window.addEventListener('load', () => {
    // TODO: what add comment
    const navExpand = [].slice.call(document.querySelectorAll('.nav-expand'));
    const navLink = '.nav-link';
    const navLinkBack = '.nav-link--back';
    const navItemActive = 'nav-item--active';

    navExpand.forEach(item => {
        item.querySelector(navLink).addEventListener('click', () => item.classList.add(navItemActive));
        item.querySelector(navLinkBack).addEventListener('click', () => item.classList.remove(navItemActive));
    });
});
