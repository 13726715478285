// TODO: NEED REWORK!

window.addEventListener('load', () => {
    showChecked();
    function showChecked () {
        const iii = document.querySelectorAll('.timeline__checkbox:not(:checked)');
        const bbb = document.querySelectorAll('.js-timeline-check-count');
        const ggg = document.querySelectorAll('.timeline__count');

        [].forEach.call(bbb, (e) => {
            e.textContent = iii.length;
        });

        [].forEach.call(ggg, (e) => {
            if (iii.length < 1) {
                e.classList.add('timeline__count--show');
            } else {
                e.classList.remove('timeline__count--show');
            }
        });
    }

    const timelineCheckboxes = document.querySelectorAll('.timeline__checkbox');
    [].forEach.call(timelineCheckboxes, (e) => {
        e.addEventListener('change', updateValue);
    });

    function updateValue (e) {
        console.log('DU2');
        const target = e.target.value;
        const result = document.querySelectorAll('.' + target);
        showChecked();

        [].forEach.call(result, (e) => {
            e.classList.toggle('js-timeline-check--inactive');
        });
    }

    const mmm = document.querySelectorAll('.timeline__reset');
    [].forEach.call(mmm, (e) => {
        e.addEventListener('click', function (e) {
            const nnn = document.querySelectorAll('.timeline__checkbox');
            const ttt = document.querySelectorAll('.js-timeline-check--inactive');
            console.log('ttt', ttt);

            [].forEach.call(ttt, (e) => {
                console.log('e', e);
                e.classList.remove('js-timeline-check--inactive');
            });
            [].forEach.call(nnn, (e) => {
                e.checked = true;
            });
            showChecked();
        });
    });
});
