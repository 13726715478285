import './scripts/konamicode';
import './scripts/loaded';

import './skeleton/navigation/mobile/_navigation';
import './skeleton/navigation/desktop/_navigation';
import './skeleton/header/_header';

import './elements/filterbar/_filterbar';
import './elements/overlay/_overlay';

console.log('shared loaded');
