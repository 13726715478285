window.addEventListener('load', () => {
    const filterbar = document.querySelector('.filterbar');
    const filterbarSticky = 'filterbar--sticky';
    Stickyfill.add(filterbar);

    const observer = new IntersectionObserver(function (entries) {
        if (entries[0].intersectionRatio === 0) {
            filterbar.classList.add(filterbarSticky);
        } else if (entries[0].intersectionRatio === 1) {
            filterbar.classList.remove(filterbarSticky);
        }
    }, {
        threshold: [0, 1]
    });

    observer.observe(document.querySelector('#filterbar-sticky'));
});

window.addEventListener('load', () => {
    function filterbarClickToggle () {
        const overlayFilterbar = document.querySelector('.js-overlay-filterbar');
        overlayFilterbar.classList.toggle('overlay--active');
    }
    const overlayToggles = document.querySelectorAll('.js-overlay-filterbar-close, .js-overlay-filterbar-open');
    [].forEach.call(overlayToggles, function(overlayToggle) {
        overlayToggle.addEventListener('click', filterbarClickToggle);
    });
});
