/*
    Konami Code
    https://gomakethings.com/how-to-create-a-konami-code-easter-egg-with-vanilla-js/
*/

window.addEventListener('load', function () {
    const pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    let current = 0;

    const keyHandler = function (event) {
        // If the key isn't in the pattern, or isn't the current key in the pattern, reset
        if (pattern.indexOf(event.key) < 0 || event.key !== pattern[current]) {
            current = 0;
            return;
        }

        // Update how much of the pattern is complete
        current++;

        // If complete, alert and reset
        if (pattern.length === current) {
            current = 0;
            const body = document.body;

            body.classList.add('konamicode');
            console.log('CHEAT ACTIVATED');
        }
    };

    // Listen for keydown events
    document.addEventListener('keydown', keyHandler, false);
});
