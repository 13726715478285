/* eslint-disable */

window.addEventListener("load", () => {

    // öööhmmm...  write it nicely or remove this shit #}
    function toggleItem(elem) {

        for (var i = 0; i < elem.length; i++) {
            elem[i].addEventListener("click", function(e) {
                const current = this;
                const element = document.querySelector(".header");
                const navigationLinkActive = "navigation-link--active";
                const headerBackgroundFix = "header--background-fix";

                for (var i = 0; i < elem.length; i++) {
                    if (current != elem[i]) {
                        elem[i].classList.remove(navigationLinkActive);
                    } else if (current.classList.contains(navigationLinkActive) === true) {
                        current.classList.remove(navigationLinkActive);
                        element.classList.remove(headerBackgroundFix);

                    } else {
                        current.classList.add(navigationLinkActive);
                        element.classList.add(headerBackgroundFix);
                    }
                }










                // e.preventDefault();
            });
        };
    }

    toggleItem(document.querySelectorAll('.navigation-link'));

});

/* eslint-enable */
