window.addEventListener('load', () => {
    const header = document.getElementById('header');
    const headerBackground = 'header--background';

    const myScrollFunc = function () {
        const y = window.scrollY;
        if (y > 200) {
            header.classList.add(headerBackground);
        } else {
            header.classList.remove(headerBackground);
        }
    };

    window.addEventListener('scroll', myScrollFunc);

    function headerSearchClickToggle () {
        const element = document.querySelector('.js-overlay-header');
        element.classList.toggle('overlay--active');
    }
    const kkks = document.querySelectorAll('.js-overlay-header-close, .js-overlay-header-open');

    [].forEach.call(kkks, function(kkk) {
        kkk.addEventListener('click', headerSearchClickToggle);
    });
});
